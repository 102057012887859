<template>
  <div class="container">

    <van-search class="self-search" v-model="keyword" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="meeting(tabCurrent)">搜索</div>
      </template>
    </van-search>

    <div class="meet-tab">
      <div class="meet-tab-item" :class="{'active':tabCurrent === 2}" @click="tabActive(2)">时间</div>
      <div class="meet-tab-item" :class="{'active':tabCurrent === 3}" @click="tabActive(3)">热度</div>
    </div>

    <!-- 会议精选 -->
    <van-empty :description="word" v-if="meetList.length == 0" />
    <div class="home-model" v-if="meetList.length > 0">
      <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onRefresh"
      >
        <div class="meet-list">
          <div v-for="(item,i) in meetList" :key="i" class="meet-card-all" @click="toBVue('/meetDetail', item.id)">
            <img :src="item.cover" alt="">
            <p class="title">{{item.title}}</p>
            <div class="desc">
              <p>{{item.title_sub}}</p>
              <p>{{item.timeShow}}</p>
            </div>
          </div>
        </div>
      </van-list>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      tabCurrent: 2,
      meetList:[],
      keyword: '',
      word: '数据加载中...',
      loading: false,
      finished: false,
      refreshStatus: false,
      formData: {
        'offset':0,
        'limit': 10,
      }
    }
  },
  created() {
    this.meeting()
  },
  methods: {
    onRefresh() {
      this.meeting()
    },
    meeting(type = '') {
      let that = this
      if (that.refreshStatus) {
        return false
      }
      that.formData.order = this.tabCurrent
      that.formData.keyword = that.keyword
      if (that.formData.offset == 0 || type == 'search') {
        that.meetList = []
      }
      that.refreshStatus = true
      this.$api.MEETING_GET(that.formData).then(res => {
        if (that.formData.offset > 0 && res.length == 0) {
          that.finished = true
          that.refreshStatus = false
        }
        if (res.length == 0) {
          that.word = '暂无数据'
          that.refreshStatus = false
          return false
        }
        res.forEach((item) => {
          that.meetList.push({
            id: item.id,
            title: item.title,
            title_sub: item.title_sub,
            cover: item.cover ? item.cover : '',
            timeShow: item.meeting_time
          })
        })
        if (res.length >= that.formData.limit) {
          that.formData.offset = that.formData.offset + 15;
        } else {
          that.finished = true
        }
        that.refreshStatus = false
      })
    },
    tabActive(item) {
      this.tabCurrent = item
      this.meeting()
    },
    toBVue(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    }
  },
}
</script>
<style lang="less" scoped>
  .meet-tab{
    display:flex;
    margin-top:15px;
    .meet-tab-item{
      display:block;
      width:74px;
      height:30px;
      background:#F4F4F4;
      text-align: center;
      line-height:30px;
      border-radius:15px;
      font-size:13px;
      color:#666;
      margin-right:8px;
      &.active{
        background:#2580FE;
        color:#fff;
      }
    }

  }

  .meet-list{
    margin-top:15px;
  }

 .meet-card-all{
    width:100%;
    margin-bottom:22px;
    padding-bottom:17px;
    border-bottom:1px solid #e9e9e9;
  }

  .meet-card-all img{
    display:block;
    width:100%;
    max-height:100%;
  }


  .meet-card-all .title{
    display:block;
    margin-top:8px;
  }

  .meet-card-all .desc{
    display:flex;
    color:#707070;
    font-size:12px;
    justify-content: space-between;
    margin-top:5px;
  }



</style>
